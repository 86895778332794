import React, { useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import {
  EMessageActionType,
  IImageCarousel,
  IImageCarouselItem,
  IMessageAction,
} from "@types";
import { Img } from "react-image";
import { ImageLoader } from "./ImageLoader";
import { createDataAttr } from "@utils";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShareLocation } from "@store/shareLocationStore";
import { shallow } from "zustand/shallow";

export type IImageCarouselProps = {
  message: IImageCarousel;
  onSendAction: (action: IMessageAction) => void;
};

export const ImageCarousel = ({
  message,
  onSendAction,
}: IImageCarouselProps) => {
  const carouselItemsRef = useRef<HTMLDivElement[]>([]);
  const { setIsOpen } = useShareLocation((state) => {
    return state;
  }, shallow);
  const _renderButton = (button: IMessageAction) => {
    if (button.type === EMessageActionType.DATE_TIME_PICKER) {
      <button
        className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-[16px] py-[8px]"
        {...createDataAttr("image-carousel-column-button")}
      >
        {button.label}
      </button>;
    }
    // link action
    if (button.type === EMessageActionType.URI_ACTION) {
      return (
        <a
          className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-[16px] py-[8px]"
          href={button.uri}
          target="_blank"
          {...createDataAttr("image-carousel-column-button")}
        >
          {button.label}
        </a>
      );
    }
    // call action
    if (button.type === EMessageActionType.CALL_ACTION) {
      return (
        <a
          className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap  px-[16px] py-[8px]"
          href={`tel:${button.phoneNo}`}
          {...createDataAttr("image-carousel-column-button")}
        >
          {button.label}
        </a>
      );
    }

    // call action
    if (button.type === EMessageActionType.LOCATION_ACTION) {
      return (
        <a
          className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap  px-[16px] py-[8px]"
          onClick={() => setIsOpen(true)}
          {...createDataAttr("image-carousel-column-button")}
        >
          {button.label}
        </a>
      );
    }

    return (
      <button
        className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap  px-[16px] py-[8px]"
        onClick={() => onSendAction(button)}
        {...createDataAttr("image-carousel-column-button")}
      >
        {button.label}
      </button>
    );
  };

  return (
    <div className="relative w-fit">
      <Splide
        hasTrack={false}
        options={{
          arrows: true,
          gap: "10px",
          autoWidth: true,
          drag: "free",
          pagination: false,
          snap: false,
          omitEnd: true,
          focus: 0,
          breakpoints: {
            200: {
              width: "250px",
            },
            380: {
              width: "300px",
            },
            460: {
              width: "400px",
            },
            640: {
              width: "580px",
            },
            840: {
              width: "771px",
            },
          },
          mediaQuery: "min",
        }}
        {...createDataAttr("image-carousel-message")}
      >
        <SplideTrack>
          {message?.columns?.map(
            (element: IImageCarouselItem, index: number) => {
              return (
                <SplideSlide
                  key={element.id}
                  className="relative flex w-[250px] flex-col drop-shadow-md"
                  {...createDataAttr("image-carousel-column")}
                >
                  <Img
                    className="h-[200px] w-[250px]  rounded-[8px]  object-cover"
                    style={{
                      cursor: !element.action?.label ? "pointer" : "default",
                    }}
                    src={[element.imageUrl, element.imageUrl]}
                    loading="lazy"
                    loader={<ImageLoader width={250} height={200} />}
                    width={250}
                    height={200}
                    onClick={() => {
                      // can click if no label
                      if (!element.action?.label) {
                        onSendAction(element.action);
                        return;
                      }
                    }}
                    {...createDataAttr("image-carousel-column-image")}
                  />
                  {
                    // show button if label is define
                    element.action?.label ? (
                      <div
                        className="absolute bottom-[20px] left-[50%] flex h-[36px] 
                  translate-x-[-50%] transform 
                  cursor-pointer items-center justify-center
                  whitespace-nowrap rounded-[20px]
                 bg-black bg-opacity-50 text-white"
                      >
                        <div className="flex flex-col items-center gap-3">
                          {_renderButton(element.action)}
                        </div>
                      </div>
                    ) : null
                  }
                </SplideSlide>
              );
            }
          )}
        </SplideTrack>
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev left-[-35px]">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <button className="splide__arrow splide__arrow--next right-[-35px]">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </Splide>
    </div>
  );
};
