import { NoInfer } from "xstate";
import { IMessageAction } from "./messageAction";
import { Area } from "@line/bot-sdk";

export const enum EMessageType {
  TEXT = "text",
  IMAGE = "image",
  CAROUSEL = "carousel",
  BUTTONS = "buttons",
  VIDEO = "video",
  FLEX = "flex",
  IMAGEMAP = "imagemap",
  ATTACHMENT = "attachment",
  POSTBACK = "postback",
  CUSTOM_POSTBACK = "custom_postback",
  FLOW_POSTBACK = "flow_postback",
  IMAGE_CAROUSEL = "image_carousel",
  ASSIGN_TICKET_POSTBACK = "assign_ticket_postback",
  LOCATION = "location",
}

export const enum EMessageStatus {
  SENDING = "sending",
  SENT = "sent",
  ERROR = "error",
}

export const enum ESender {
  BOT = "bot",
  USER = "user",
}

export type ICarouselAction = {
  id: string;
  label: string;
  templateName: string;
  type: string;
};

export type ICarousel = {
  actions: IMessageAction[];
  id: string;
  text: string;
  thumbnailImageUrl: string;
  title: string;
};
export type IButtonAction = {
  id: string;
  label: string;
  templateName: string;
  type: string;
};

export type IBaseSize = {
  width: number;
  height: number;
};

export type IQuickReplyItem = {
  type: string;
  action: IMessageAction;
};

export type IQuickReply = {
  items: IQuickReplyItem[];
};

export type IBaseMessage = {
  id: string;
  sender: ESender;
  status: EMessageStatus;
  createdAt?: string;
};

export type ITextMessage = {
  type: EMessageType.TEXT;
  text: string;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IImageMessage = {
  type: EMessageType.IMAGE;
  originalContentUrl: string;
  previewImageUrl: string;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type ICarouselList = {
  type: EMessageType.CAROUSEL;
  altText: string;
  columns: ICarousel[];
  quickReply?: IQuickReply;
  imageAspectRatio?: "rectangle" | "square";
  /**
   * Size of the image. Specify one of the following values:
   *
   * - `cover`: The image fills the entire image area. Parts of the image that
   *   do not fit in the area are not displayed.
   * - `contain`: The entire image is displayed in the image area. A background
   *   is displayed in the unused areas to the left and right of vertical images
   *   and in the areas above and below horizontal images.
   *
   * Applies to all columns. The default value is `cover`.
   */
  imageSize?: "cover" | "contain";
} & IBaseMessage;

export type IImageCarouselItem = {
  id: string;
  imageUrl: string;
  action: IMessageAction;
};

export type IImageCarousel = {
  type: EMessageType.IMAGE_CAROUSEL;
  altText: string;
  columns: IImageCarouselItem[];
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IButton = {
  type: EMessageType.BUTTONS;
  altText: string;
  text: string;
  actions: IMessageAction[];
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IVideo = {
  type: EMessageType.VIDEO;
  originalContentUrl: string;
  previewImageUrl: string;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IFlex = {
  type: EMessageType.FLEX;
  contents: any;
  altText: string;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IImagemapAction = IMessageAction & { area: Area };

export type IImageMap = {
  type: EMessageType.IMAGEMAP;
  actions: IImagemapAction[];
  altText: string;
  baseSize: IBaseSize;
  baseUrl: string;
  imgHeight: number;
  imgUrl: string;
  imgWidth: number;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type IAttachment = {
  type: EMessageType.ATTACHMENT;
  name: string;
  ext: string;
  url: string;
} & IBaseMessage;

export type ILocation = {
  type: EMessageType.LOCATION;
  description: string;
  latitude: number;
  longitude: number;
  quickReply?: IQuickReply;
} & IBaseMessage;

export type ICombinedMessage =
  | ITextMessage
  | IImageMessage
  | ICarouselList
  | IButton
  | IVideo
  | IFlex
  | IAttachment
  | IImageCarousel
  | IImageMap
  | ILocation;
